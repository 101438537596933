import { Menu, PageHeader, Space, Dropdown, Image, Button, Grid, Divider } from 'antd';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { LeftMenu } from './Menu';
import { AvatarAndUser } from './AvatarUser';
import { TeamSelect } from './TeamSelect';
import { useContext, useEffect, useState, useRef, ReactNode } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { StyledMenu, StyledMenuItem } from './PageHeader.styled';
import classNames from 'classnames';
import { TeamContext } from '../contexts/TeamContext';
import 'draft-js/dist/Draft.css';
import CaretDownOutlined from '@ant-design/icons/lib/icons/CaretDownOutlined';

const dropdownmenu = (value: any) => (
  <Menu mode="horizontal">
    {value.map((dropdownitem) => {
      return (
        <Menu.Item key={dropdownitem.text}>
          {dropdownitem.disabled ? (
            dropdownitem.text
          ) : (
            <Button type="text">
              <Link to={dropdownitem.to}>{dropdownitem.text}</Link>
            </Button>
          )}
        </Menu.Item>
      );
    })}
  </Menu>
);
const { useBreakpoint } = Grid;

type MenuItemType = {
  to?: string;
  text?: ReactNode;
  disabled?: boolean;
  children?: MenuItemType[];
};

const MenuItems: MenuItemType[] = [
  {
    to: '/dashboard',
    text: 'Overview',
  },
  {
    to: '/trending',
    text: 'Program Trends',
  },
  {
    to: '/relationship',
    text: 'Relationship',
    children: [
      {
        to: '/npsdashboard',
        text: 'NPS Analysis',
      },
      {
        to: '/tssdashboard',
        text: 'TSS Analysis',
      },
    ],
  },
  {
    to: '/feedback',
    text: 'Feedback',
  },
  {
    to: '/statistic',
    text: 'Statistics',
  },
  {
    to: '/utilities',
    text: 'Utilities',
    children: [
      {
        to: '/guideline',
        text: 'Guideline',
      },
      {
        to: '/healthcheck',
        text: 'Health Check',
      },
      {
        to: '/#',
        text: <Divider style={{ margin: 0 }} />,
        disabled: true,
      },
      {
        to: '/import-history',
        text: 'Import History',
      },
    ],
  },
];

export const Header = () => {
  const history = useHistory();
  const location = useLocation();
  const [selectedTeam, setSelectedTeam] = useState<number>(undefined);
  const {
    user: { isAdmin },
  } = useContext(AuthContext);
  const { teams } = useContext(TeamContext);
  const [width, setWidth] = useState(window.innerWidth);
  const [mobileSize, setMobileSize] = useState(false);
  const [headerItem, setHeaderItem] = useState(MenuItems);
  const [dropdownItem, setDropdownItem] = useState([]);
  const ref = useRef(null);

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
  };
  const breakpointDropDownHeader = [895, 810, 750, 670, 560];
  const breakpoint = 1000;

  useEffect(() => {
    location.pathname !== history.location.pathname && setSelectedTeam(undefined);
  }, [location]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  useEffect(() => {
    let showDropDownHeader = width - ref.current.offsetWidth;
    let headerItem = MenuItems;
    let dropdownItem = [];
    if (width >= breakpoint) {
      for (let n = 0; n < 5; n++) {
        if (showDropDownHeader <= breakpointDropDownHeader[n]) {
          dropdownItem = dropdownItem.concat(headerItem.slice(-1));
          headerItem = headerItem.slice(0, -1);
        }
      }
    }
    setDropdownItem(dropdownItem);
    setHeaderItem(headerItem);
    setMobileSize(width < breakpoint);
  }, [width, ref.current]);

  const params = new URLSearchParams(location.search);
  const dateId = params.get('dateId');

  const handleNavigate = (value: number) => {
    const team = teams.find((item) => item.id === value);
    if (team) {
      setSelectedTeam(value);
      switch (team.type) {
        case 'Engagement':
          history.push(`/engagement/${value}${dateId ? `?dateId=${dateId}` : ''}`);
          break;
        case 'Team':
          history.push(`/project/${value}${dateId ? `?dateId=${dateId}` : ''}`);
          break;
      }
    }
  };

  const { sm, md } = useBreakpoint();
  return (
    <PageHeader className="site-page-header ">
      <div className="nav-container">
        <Space align="center" size="middle" className="logo-holder">
          <LeftMenu isAdmin={isAdmin} isMobileSize={mobileSize} dateId={dateId} />
          <Link style={{ display: 'flex' }} to="/">
            <Image className="logo" width={100} src="/assets/img/HSBC-logo.png" preview={false} />
          </Link>
          {!mobileSize && (
            <StyledMenu>
              <div className="header-btn">
                {headerItem.map((menuitem) => {
                  if (menuitem?.children?.length > 0)
                    return (
                      <Dropdown
                        overlay={
                          <Menu>
                            {menuitem.children.map((child) => (
                              <StyledMenuItem
                                key={child.to}
                                className={location.pathname.includes(`${menuitem.to}${child.to}`) ? 'item-active' : ''}
                                disabled={child.disabled}
                              >
                                <Link
                                  className={
                                    location.pathname.includes(`${menuitem.to}${child.to}`) ? 'item-text-active' : ''
                                  }
                                  to={`${menuitem.to}${child.to}`}
                                >
                                  {child.text}
                                </Link>
                              </StyledMenuItem>
                            ))}
                          </Menu>
                        }
                        placement="bottomCenter"
                      >
                        <Button
                          type="text"
                          size="large"
                          className={classNames({ 'is-active': location.pathname.includes(menuitem.to) })}
                        >
                          {menuitem.text}
                          <CaretDownOutlined />
                        </Button>
                      </Dropdown>
                    );
                  return (
                    <Button
                      key={menuitem.to}
                      type="text"
                      size="large"
                      className={classNames({ 'is-active': location.pathname === menuitem.to })}
                    >
                      {menuitem.disabled ? menuitem.text : <Link to={menuitem.to}>{menuitem.text}</Link>}

                      {/* <Link to={menuitem.to}>{menuitem.text}</Link> */}
                    </Button>
                  );
                })}
                {dropdownItem.length > 0 && (
                  <Dropdown overlay={dropdownmenu(dropdownItem)}>
                    <CaretDownOutlined />
                  </Dropdown>
                )}
              </div>
            </StyledMenu>
          )}
        </Space>
      </div>
      <div className="user-container" ref={ref}>
        {md && (
          <TeamSelect
            value={selectedTeam}
            className="user-container-team-select"
            showSearch
            type={['Team', 'Engagement']}
            onSelect={handleNavigate}
            defaultActiveFirstOption={false}
            placeholder="Search for project team"
          />
        )}
        <AvatarAndUser isMobileSize={!sm} />
      </div>
    </PageHeader>
  );
};
