import { CalendarOutlined, CheckCircleFilled, CloseCircleFilled, UserAddOutlined } from '@ant-design/icons';
import { Card, Collapse, Popover, Space, Typography, Breadcrumb, Col, Row, Input } from 'antd';
import { ImportHistoryDto } from '../../types/importHistoryDto';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { TeamInfo } from '../../types/team';
import { Link } from 'react-router-dom';

const { Panel } = Collapse;
const { Title, Text } = Typography;

interface IProps {
  key: string | number;
  data: ImportHistoryDto;

  teamsMetadata: TeamInfo[];
}

export const HistoryContentPanel = ({ key, data, teamsMetadata, ...rest }: IProps) => {
  const [query, setQuery] = useState<string>('');

  const impDate = useMemo(() => moment.utc(data.importTime).local().format('YYYY-MM-DD HH:mm:ss'), [data]);

  const mappedData = useMemo(
    () =>
      data.importedTeams
        ?.map((teamName) => teamsMetadata.find((m) => m.name === teamName))
        ?.filter(
          (x) =>
            !query ||
            x.name.toLowerCase().includes(query.toLowerCase()) ||
            x.engagement?.name.toLowerCase().includes(query.toLowerCase()) ||
            x.department?.name.toLowerCase().includes(query.toLowerCase())
        )
        ?.sort(
          (a, b) =>
            a.department?.name?.localeCompare(b.department?.name) ||
            a.engagement?.name?.localeCompare(b.engagement?.name) ||
            a.name.localeCompare(b.name)
        ) ?? [],
    [data.importedTeams, query, teamsMetadata]
  );

  const renderStatus = (isSuccess: boolean) => {
    if (isSuccess) {
      return (
        <Text type="success">
          <CheckCircleFilled />
        </Text>
      );
    }
    return (
      <Text type="danger">
        <CloseCircleFilled />
      </Text>
    );
  };

  return (
    <Panel
      {...rest}
      key={key}
      header={
        <Space>
          {renderStatus(data.isSuccess)}
          {data.action}
        </Space>
      }
      extra={
        <Popover
          content={
            <span>
              Import Date: {impDate}
              <br />
              Import By: {data.userName}
            </span>
          }
        >
          <Space>
            <Text type="secondary">
              <i>
                <CalendarOutlined /> {impDate}
              </i>
            </Text>
            <Text type="secondary">
              <i>|</i>
            </Text>
            <Text type="secondary">
              <i>
                <UserAddOutlined /> {data.userName}
              </i>
            </Text>
          </Space>
        </Popover>
      }
    >
      <Row>
        <Col span={18}>
          <Title level={5}>
            <span>
              Affected Teams <i>(gathered from Import file)</i>:{' '}
            </span>
            <span className="aff-team-count">
              {data.importedTeams.length === 0 ? 'No data' : `${data.importedTeams.length} team(s)`}
            </span>
          </Title>
        </Col>
        <Col span={6}>
          <Input allowClear placeholder="I'm feeling lucky..." onChange={(e) => setQuery(e.currentTarget.value)} />
        </Col>
        <Col span={24}>
          {(!data.importedTeams || data.importedTeams.length === 0) && <i>(No data)</i>}
          {mappedData.length === 0 && <i>(No match)</i>}
          {mappedData.length > 0 &&
            mappedData.map((teamMeta, idx) => {
              if (!teamMeta.id) {
                return (
                  <div>
                    <i>{`${teamMeta.name} (Invalid team)`}</i>
                  </div>
                );
              }

              return (
                <Breadcrumb key={idx}>
                  {teamMeta?.department && (
                    <Breadcrumb.Item>
                      <span style={{ color: '#00000073' }}>{teamMeta.department.name}</span>
                    </Breadcrumb.Item>
                  )}
                  {teamMeta?.engagement && (
                    <Breadcrumb.Item>
                      <Link to={`/engagement/${teamMeta.engagement.id}`} style={{ color: '#1890ff' }}>
                        {teamMeta.engagement.name}
                      </Link>
                    </Breadcrumb.Item>
                  )}
                  <Breadcrumb.Item>
                    <Link to={`/project/${teamMeta.id}`} style={{ color: '#1890ff' }}>
                      <b>{teamMeta.name}</b>
                    </Link>
                  </Breadcrumb.Item>
                </Breadcrumb>
              );
            })}
        </Col>
      </Row>
    </Panel>
  );
};
