import { Entity } from './entity';

export type TeamType = 'Program' | 'Department' | 'Engagement' | 'Team';

export const TeamTypeEnum = {
  Department: 'Department',
  Engagement: 'Engagement',
  Team: 'Team',
  Program: 'Program',
} as const;

export interface SelectOptions<T = string> {
  value: T;
  label: string;
}

export const teamOptions: SelectOptions<TeamType>[] = [
  {
    label: 'Department',
    value: 'Department',
  },
  {
    label: 'Project',
    value: 'Engagement',
  },
  {
    label: 'Team',
    value: 'Team',
  },
];

export interface TeamModel {
  name: string;
  code: string;
  type: TeamType;
  parentId?: number;
}

export interface NotiTeamModel {
  teamId: number;
  notified: boolean;
}

export interface NotiTeamRequest {
  teamId: number;
  dateId: number;
}

export interface TeamResponse extends Entity {
  name: string;
  code: string;
  type: TeamType;
  isConfigured: boolean;
  parentId?: number;
  lastModificationTime?: string;
  lastModifierUserId?: number;
  creationTime: string;
  creatorUserId: number;
}

type SimplifyTeam = Entity & {
  name?: string;
};

export type TeamInfo = SimplifyTeam & {
  department?: SimplifyTeam;
  engagement?: SimplifyTeam;
};
