import type { ImportHistoryDto } from '../../types/importHistoryDto';

import { Collapse, Typography } from 'antd';
import { useCallback, useContext, useMemo } from 'react';
import { TeamContext } from '../../contexts/TeamContext';
import { TeamInfo } from '../../types/team';
import { HistoryContentPanel } from './HistoryContentPanel';
import { StyledHistoryCard } from './HistorySection.styled';

const { Title } = Typography;

interface IProps {
  title?: string;
  data?: ImportHistoryDto[];
}

export const HistorySection = ({ title, data }: IProps) => {
  const { teams } = useContext(TeamContext);

  const getTeamInfo = useCallback(
    (teamId: string | number) => teams?.find((item) => item.id === teamId || item.name === teamId),
    [teams]
  );

  const teamsMetadata = useMemo(() => {
    return data
      .flatMap((x) => x.importedTeams)
      .filter((value, index, array) => array.indexOf(value) === index)
      .map((x) => {
        const team = getTeamInfo(x);

        if (!team) {
          return {
            name: x,
          } as TeamInfo;
        }

        return {
          id: team.id,
          name: team.name,
          engagement: getTeamInfo(team.parentId),
          department: getTeamInfo(getTeamInfo(team.parentId)?.parentId),
        } as TeamInfo;
      });
  }, [data, getTeamInfo]);

  return (
    <StyledHistoryCard>
      <Title level={3}>{title ?? 'Title'}</Title>
      <Collapse>
        {data?.map((r, idx) => (
          <HistoryContentPanel key={`content__${idx}`} data={r} teamsMetadata={teamsMetadata} />
        ))}
      </Collapse>
    </StyledHistoryCard>
  );
};
