import { LoadingOutlined } from '@ant-design/icons';
import { Button, Col, Empty, Row, Spin, Timeline } from 'antd';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';

import { getImportHistories, getImportHistoryYears } from '../../apis/importHistory';
import { useFetch } from '../../hooks/useFetch';
import { ImportHistoryDto } from '../../types/importHistoryDto';
import { HistorySection } from './HistorySection';

export const ImportHistory = () => {
  const [validYears, setValidYears] = useState<number[]>([]);
  const [selectedYear, setSelectedYear] = useState<number>(null);
  const [data, setData] = useState<ImportHistoryDto[]>();
  const [loading, setLoading] = useState<boolean>(false);

  const { data: years, loading: yearLoading } = useFetch(() => getImportHistoryYears());

  const groupedData = useMemo<Record<string, ImportHistoryDto[]>>(
    () =>
      data?.reduce((acc, item) => {
        const monthYear = moment.utc(item.importTime).format('YYYY-MM');
        if (!acc[monthYear]) {
          acc[monthYear] = [];
        }
        acc[monthYear].push(item);
        return acc;
      }, {}),
    [data]
  );

  const fetchData = async (year: number) => {
    if (!year) {
      return;
    }

    setLoading(true);

    try {
      const data = await getImportHistories(year);
      data && setData(data);
    } catch (error) {
      return <div>{JSON.stringify(error)}</div>;
    }

    setLoading(false);
  };

  useEffect(() => {
    if (!years || years.length === 0) {
      setLoading(false);
      return;
    }
    const validYears = Array.from(new Set(years));
    setValidYears(validYears);
    setSelectedYear(validYears?.[0]);
  }, [years]);

  useEffect(() => {
    fetchData(selectedYear);
  }, [selectedYear]);

  const handleYearChange = (year: number) => {
    setSelectedYear(year);
  };

  return (
    <>
      <Helmet>
        <title>Import history</title>
      </Helmet>

      <div className="page-home page-container">
        <section className="section-container">
          <div className="section-title-container">
            <span className="section-title">Import history</span>
            <div className="section-description">
              <span>
                The Import History page is designed to help you track all import actions performed by administrators
              </span>
              <br />
              <span>
                Click a year to see monthly import details, including status, action, date & time, performed by, and
                affected teams.
              </span>
            </div>
          </div>
          {loading || yearLoading ? (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              tip="loading..."
              style={{ display: 'flex', justifyContent: 'center' }}
              size="large"
            />
          ) : (
            <Row>
              {years?.length === 0 && (
                <Col xs={24}>
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </Col>
              )}
              {years?.length > 0 && (
                <>
                  <Col xs={4}>
                    <Timeline>
                      {validYears?.map((year) => (
                        <Timeline.Item key={year}>
                          <Button
                            style={{ color: year === selectedYear ? 'white' : 'black' }}
                            onClick={() => handleYearChange(year)}
                            type={year === selectedYear ? 'primary' : 'text'}
                          >
                            {year}
                          </Button>
                        </Timeline.Item>
                      ))}
                    </Timeline>
                  </Col>
                  <Col xs={20}>
                    {!loading && (!data || data.length === 0) && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}

                    {!loading &&
                      data?.length > 0 &&
                      Object.entries(groupedData ?? {}).map(([monthYear, data], idx) => (
                        <HistorySection
                          key={`${monthYear}__${idx}`}
                          title={moment(monthYear, 'YYYY-MM').format('MMMM YYYY')}
                          data={data}
                        />
                      ))}
                  </Col>
                </>
              )}
            </Row>
          )}
        </section>
      </div>
    </>
  );
};
