import { MeasureQuery, MeasureResponse, ProjectMetricFactResponse, TeamActualModel } from '../types/metrics';
import { fetchAsync } from '../utils/fetch';

const toUrlSearchParams = (query: any) => {
  const urlParams = new URLSearchParams(query);
  return urlParams.toString();
};

export const fetchMeasures = (query: MeasureQuery): Promise<MeasureResponse[]> =>
  fetchAsync(`/api/measure?${toUrlSearchParams(query)}`);

export const updateActual = (
  teamId,
  dateId,
  projectMetricFact: TeamActualModel[]
): Promise<ProjectMetricFactResponse> =>
  fetchAsync(`/api/measure/update/${teamId}/${dateId}`, {
    method: 'PUT',
    body: projectMetricFact,
  });
