import Card from 'antd/lib/card';
import styled from 'styled-components';

export const StyledHistoryCard = styled(Card).attrs(() => ({
  bordered: false,
}))`
  & > .ant-card-body {
    padding: 0;
  }
  & + & {
    margin-top: 24px;
  }
`;
