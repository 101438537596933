import type { CategoryWeightResponse, KPIWeightResponse } from '../types/metrics';

import { Empty, Form, FormInstance, InputNumber } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import { forwardRef, useEffect } from 'react';

interface IProps {
  categoryWeight: CategoryWeightResponse[];
  kpiWeight: KPIWeightResponse[];
  data: any;
  editableColName: string;
}

const getRowSpans = (arr, key) => {
  let sameValueLength = 0;
  const rowSpans = [];
  for (let i = arr.length - 1; i >= 0; i--) {
    if (i === 0) {
      rowSpans[i] = sameValueLength + 1;
      continue;
    }
    if (arr[i][key] === arr[i - 1][key]) {
      rowSpans[i] = 0;
      sameValueLength++;
    } else {
      rowSpans[i] = sameValueLength + 1;
      sameValueLength = 0;
    }
  }
  return rowSpans;
};

export const TeamScoreUpdateTable = forwardRef<FormInstance, IProps>(
  ({ data, kpiWeight, categoryWeight, editableColName }: IProps, ref) => {
    const [form] = Form.useForm();

    useEffect(() => {
      form.setFieldsValue(data);
    }, [form, data]);

    if (kpiWeight.length === 0) {
      return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
    }

    kpiWeight = kpiWeight.sort((a, b) => (a.categoryName.toLowerCase() > b.categoryName.toLowerCase() ? 1 : -1));
    const rowSpans = getRowSpans(kpiWeight, 'categoryName');

    const dateCols: ColumnsType = [
      {
        title: 'Category',
        dataIndex: 'categoryName',
        width: '15%',
        key: 'categoryName',
        fixed: 'left',
        render: (value, record: KPIWeightResponse, index) => {
          const obj = {
            children:
              record.categoryName +
              ' (' +
              categoryWeight?.find((item) => item.name === record.categoryName)?.weight * 100 +
              '%)',
            props: { rowSpan: rowSpans[index] },
          };
          return obj;
        },
        onCell: (record: KPIWeightResponse) => ({
          style: {
            backgroundColor: record?.color,
          },
        }),
      },
      {
        dataIndex: 'name',
        title: 'KPI',
        fixed: 'left',
        width: '30%',
        key: 'name',
        render: (value, record: KPIWeightResponse, index) => record.name,
        onCell: (record: KPIWeightResponse) => ({
          style: {
            backgroundColor: record?.color,
          },
        }),
      },
      {
        title: 'KPI Weight',
        dataIndex: 'weight',
        key: 'weight',
        width: '5%',
        align: 'center',
        render: (value, record: KPIWeightResponse, index) => (!isNaN(value) ? `${(value * 100).toFixed(2)}%` : value),
      },
      {
        title: editableColName,
        dataIndex: 'name',
        width: '5%',
        align: 'center',
        key: editableColName,
        // eslint-disable-next-line react/display-name
        render: (value, record: KPIWeightResponse, index) => {
          return (
            <Form.Item
              name={record.name}
              style={{ margin: 0 }}
              key={record.name}
              rules={[
                {
                  required: true,
                  message: `Please input ${record.name}!`,
                },
                {
                  validator: async (_, value) => {
                    if (isNaN(value) || value < 0) {
                      return Promise.reject('Please input a non-negative number!');
                    }
                    return Promise.resolve();
                  },
                },
              ]}
              initialValue={data[record.name]}
            >
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>
          );
        },
      },
    ];
    return (
      <Form form={form} ref={ref}>
        <Table
          className="project-monthly-data-table"
          columns={dateCols}
          bordered
          rowKey="label"
          dataSource={kpiWeight}
          pagination={false}
        />
      </Form>
    );
  }
);
