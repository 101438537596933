import { ImportHistoryDto } from '../types/importHistoryDto';
import { fetchAsync } from '../utils/fetch';

export const getImportHistories = (year: number): Promise<ImportHistoryDto[]> =>
  fetchAsync(`/api/import-history?year=${year}`, {
    method: 'GET',
  });

export const getImportHistoryYears = (): Promise<number[]> =>
  fetchAsync(`/api/import-history/years`, {
    method: 'GET',
  });
