import { Typography } from 'antd';
import { Upload, Button, DatePicker, Table, Alert } from 'antd';
import { useState } from 'react';
import classNames from 'classnames';
import fetchTargetTemplate from '../apis/fetchTargetTemplate';
import { postTargetTemplate } from '../apis/postTargetTemplate';
import {
  ImportBody,
  ImportContent,
  ImportDescription,
  ImportHeader,
  ImportLayout,
} from '../layouts/ImportLayout.styled';
import { PageTitle } from '../layouts/AdminLayout.styled';
import { Helmet } from 'react-helmet';
import downloadFileFromBlob from '../utils/downloadFileFromBlob';

const { Title } = Typography;

const columns = [
  {
    title: 'Position',
    dataIndex: 'members',
  },
  {
    title: 'Message',
    dataIndex: 'message',
  },
];

export const ImportTarget = () => {
  const [isLoadingDownload, setIsLoadingDownload] = useState<boolean>(false);
  const [isLoadingUpload, setIsLoadingUpload] = useState<boolean>(false);
  const [downloadError, setDownloadError] = useState();
  const [file, setFile] = useState();
  const [isDisableUpload, setIsDisableUpload] = useState<boolean>(true);
  const [isDisableBrowse, setIsDisableBrowse] = useState<boolean>(true);
  const [isDisableDownload, setIsDisableDownload] = useState<boolean>(true);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [dateId, setDateId] = useState<number>();
  const [listErrors, setListErrors] = useState<object[]>();
  const [errorMsg, setErrorMsg] = useState();

  const handleMonthPicker = (month) => {
    if (month !== null) {
      const monthPicker = month.format().replace('-', '').slice(0, 6);
      setDateId(monthPicker);
      setIsDisableDownload(false);
      setIsDisableBrowse(false);
      setErrorMsg(null);
    }
  };

  const setSelectedFile = (selectedFile) => {
    setFile(selectedFile);
    setListErrors(null);
    setIsDisableUpload(false);
    setErrorMsg(null);
    return false;
  };

  const handleUpload = async () => {
    try {
      setIsLoadingUpload(true);
      setIsOpen(false);
      setIsDisableUpload(true);
      setListErrors(null);
      setErrorMsg(null);
      const result = await postTargetTemplate(file, dateId);
      setIsOpen(true);
    } catch (error) {
      if (error.data.error.validationErrors !== null) {
        let err = error.data.error.validationErrors.map((e) => e);
        setErrorMsg(error.data.error.message);
        setListErrors(err);
      } else {
        alert('Failed! Please correct the file.');
      }
    } finally {
      setIsLoadingUpload(false);
    }
  };

  const downloadFile = async () => {
    try {
      setIsLoadingDownload(true);
      const result = await fetchTargetTemplate(dateId);
      downloadFileFromBlob(result.blob, result.fileName);
    } catch (error) {
      setDownloadError(error);
    } finally {
      setIsLoadingDownload(false);
    }
  };

  return (
    <ImportLayout>
      <Helmet>
        <title>Import Target</title>
      </Helmet>
      <ImportHeader>
        <PageTitle>
          <Title level={3}>Import Target</Title>
        </PageTitle>
      </ImportHeader>
      <ImportBody>
        <ImportContent>
          <ImportDescription>Click here to download the latest import template</ImportDescription>
          <Button
            size="large"
            type="primary"
            onClick={downloadFile}
            loading={isLoadingDownload}
            disabled={isDisableDownload}
          >
            Download
          </Button>
          <div>{JSON.stringify(downloadError)}</div>
        </ImportContent>
        <Title level={4}>Bulk upload Target Template</Title>
        <ImportContent>
          <Title className="title" level={5} style={{ fontWeight: 'bold' }}>
            Select a month <p className={classNames('month-picker', { 'has-value': dateId })}>*</p>
          </Title>
          <DatePicker picker="month" style={{ marginRight: '30px' }} onChange={handleMonthPicker} allowClear={false} />
        </ImportContent>
        <Upload beforeUpload={setSelectedFile} accept=".xlsx" maxCount={1}>
          <Button size="large" type="primary" style={{ marginRight: '20px' }} disabled={isDisableBrowse}>
            Browse
          </Button>
        </Upload>
        <Button
          size="large"
          type="primary"
          onClick={handleUpload}
          disabled={isDisableUpload}
          loading={isLoadingUpload}
          style={{ marginTop: '20px' }}
        >
          {isLoadingUpload ? 'Uploading' : 'Start Upload'}
        </Button>
        {isOpen && <Alert closable message="Import Successfully" type="success" showIcon />}
        {errorMsg && <Alert closable message={errorMsg} type="error" showIcon onClose={() => setErrorMsg(null)} />}
        {listErrors?.length > 0 && (
          <Table
            className="table-error"
            scroll={{ y: 350 }}
            columns={columns}
            dataSource={listErrors}
            pagination={false}
          />
        )}
      </ImportBody>
    </ImportLayout>
  );
};
